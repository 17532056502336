import { default as indexhFmAAAmYzrMeta } from "/var/www/sites/auth.pos2.halifax.dk/releases/20240916111948/pages/index.vue?macro=true";
import { default as _91token_93DDTY6vE1k2Meta } from "/var/www/sites/auth.pos2.halifax.dk/releases/20240916111948/pages/password/reset/[token].vue?macro=true";
import { default as indexXRVIbgN7kmMeta } from "/var/www/sites/auth.pos2.halifax.dk/releases/20240916111948/pages/password/reset/index.vue?macro=true";
import { default as redirectJy2JKGRl9AMeta } from "/var/www/sites/auth.pos2.halifax.dk/releases/20240916111948/pages/redirect.vue?macro=true";
export default [
  {
    name: indexhFmAAAmYzrMeta?.name ?? "index",
    path: indexhFmAAAmYzrMeta?.path ?? "/",
    meta: indexhFmAAAmYzrMeta || {},
    alias: indexhFmAAAmYzrMeta?.alias || [],
    redirect: indexhFmAAAmYzrMeta?.redirect,
    component: () => import("/var/www/sites/auth.pos2.halifax.dk/releases/20240916111948/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93DDTY6vE1k2Meta?.name ?? "password-reset-token",
    path: _91token_93DDTY6vE1k2Meta?.path ?? "/password/reset/:token()",
    meta: _91token_93DDTY6vE1k2Meta || {},
    alias: _91token_93DDTY6vE1k2Meta?.alias || [],
    redirect: _91token_93DDTY6vE1k2Meta?.redirect,
    component: () => import("/var/www/sites/auth.pos2.halifax.dk/releases/20240916111948/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexXRVIbgN7kmMeta?.name ?? "password-reset",
    path: indexXRVIbgN7kmMeta?.path ?? "/password/reset",
    meta: indexXRVIbgN7kmMeta || {},
    alias: indexXRVIbgN7kmMeta?.alias || [],
    redirect: indexXRVIbgN7kmMeta?.redirect,
    component: () => import("/var/www/sites/auth.pos2.halifax.dk/releases/20240916111948/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: redirectJy2JKGRl9AMeta?.name ?? "redirect",
    path: redirectJy2JKGRl9AMeta?.path ?? "/redirect",
    meta: redirectJy2JKGRl9AMeta || {},
    alias: redirectJy2JKGRl9AMeta?.alias || [],
    redirect: redirectJy2JKGRl9AMeta?.redirect,
    component: () => import("/var/www/sites/auth.pos2.halifax.dk/releases/20240916111948/pages/redirect.vue").then(m => m.default || m)
  }
]